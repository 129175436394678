html,
body {
  margin: 0;
  padding: 0;
  font-family: 'M PLUS 1p', sans-serif !important;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

header .item:not(.login) img {
  max-width: 100%;
}

#address-input .pac-container {
  margin-top: -210px;
}

/* creates part of the curve */
.bubble-left,
.bubble-right {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.bubble-left,
.bubble-right {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.bubble-left::before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #dbe3f7;
  border-bottom-right-radius: 15px;
}

.bubble-left::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: #fbf9ef;
  border-bottom-right-radius: 10px;
}

.bubble-right::before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: #c5eafd;
  border-bottom-left-radius: 15px;
}

.bubble-right::after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: #fbf9ef;
  border-bottom-left-radius: 10px;
}

.ant-collapse,
.ant-collapse-item {
  border-color: #18184a !important;
  border-radius: 0 !important;
}

.ant-collapse-header {
  background-color: #18184a;
  color: #fff !important;
  font-size: 1em !important;
  border-radius: 0 !important;
}

/* creates part of the curved pointy bit */

/* PlaceCard image slider prev/next buttons */
.slick-prev {
  margin-left: 30px;
  z-index: 1;
}

.slick-next {
  margin-right: 30px;
  z-index: 1;
}
.auto-complete-filter {
  position: sticky !important;
  top: 10px;
  width: 100%;
  padding: 0 15px !important;
  z-index: 1;
}

.auto-complete-filter .ant-select .ant-input {
  border-radius: 0 !important;
}

.auto-complete-filter .ant-select-search__field__mirror {
  display: none !important;
}

@media screen and (max-width: 768px) {
  header {
    position: fixed;
    z-index: 98;
  }
  .switcher {
    position: fixed;
    top: 64px;
    z-index: 98;
  }
  .panel-conteiner {
    padding-top: 104px;
  }
  #message-list {
    padding-top: 104px;
    padding-bottom: 102px;
  }

  .message-input {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 98;
  }
}
.react-images__blanket,
.react-images__positioner {
  z-index: 99 !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mobile .logo {
    padding: 5px 5px 0px 15px !important;
  }

  .mobile header div:not(.logo).ant-col a img {
    height: 58px !important;
    width: 38px !important;
  }
}

@media screen and (max-width: 999px) {
  .logo {
    width: calc(100% - 192px);
  }

  .logo img {
    max-height: 38px;
    max-width: 100%;
  }

  header .ant-col:not(.logo) {
    width: 64px;
  }
}
@media screen and (max-width: 376px) {
  .option-panel {
    max-width: 370px;
    padding-left: 30px !important;
  }
}
@media screen and (max-width: 321px) {
  .option-panel {
    max-width: 315px;
    padding-left: 80px !important;
  }
}

@media screen and (max-height: 220px) {
  .header {
    height: 0;
    padding: 0 !important;
  }
}

/* Carousel modal prev/next button */
.css-8wgz7g {
  color: #18184a;
}

.css-xfk84m {
  background: rgba(255, 255, 255) !important;
}

.css-1h82jk3 {
  background: rgba(255, 255, 255) !important;
}
